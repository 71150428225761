export const optionText = [
  {
    // title: '检品option费',
    id: "inspectionCost",
    title: "検品オプション料金",
    //     {text:`  RAKUMART非常注重产品质量，为此我们设有专业的检品服务团队，确保每个订单里的产品都符合质量标准。<br> 检品option费用可确保这个过程能够顺畅进行，并且您所购买的产品达到了标准。<br>
    // 我们会依据订单里面的产品数量以及种类等因素计算相应的检品服务费用。<br> 经过我们检品会大幅降低退货率，并且保持了良好的口碑。`},
    //     {text:`  对于下列费用一览表中没有的操作与服务，我们也会灵活应对，请您有问题务必随时咨询。`}
    textList: [
      {
        text: `RAKUMARTは製品の品質管理について日本人目線で対応しており、専門の品質検査サービスチームを設けています。<br> これにより、各注文の製品が品質基準を満たしていることを確認しています。<br>
    検品オプション料金は、このプロセスがスムーズに進行し、お客様が購入した製品が基準に達していることを保証するためのものです。<br> 注文内の製品の数量や種類などの要素に基づいて、対応する検品サービス料金を計算します。私たちの検品により返品率を大幅に低下させ、良好な評価を維持しています。`,
      },
      {
        text: `料金表に掲載されていない操作やサービスについても柔軟に対応いたしますので、ご質問がある場合はお気軽にお問い合わせください。`,
      },
    ],
    row: [
      {
        video: "https://www.youtube.com/embed/Vnf8jDMpXuc?si=ctsVCF1Lzkx331Qg",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202309/6502c2b1afdff.jpg",
        // label: `直接发日本仓贴标`,
        label: `商品ラベル貼り付け-amazon<br> <span style="">※opp袋交換も含まれます <br>日本以外への発送も対応可</span>`,
        val1: "0.8元/点",
        val2: "0.8元/点",
        val3: "1元/点",
        id: "368",
      },
      {
        video: "https://www.youtube.com/embed/Vnf8jDMpXuc?si=ctsVCF1Lzkx331Qg",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202309/6502c2b1afdff.jpg",
        // label: `直接发日本仓贴标`,
        label: `商品ラベル貼り付け-楽天<br> <span style="">※opp袋交換も含まれます <br>日本以外への発送も対応可</span>`,
        val1: "0.8元/点",
        val2: "0.8元/点",
        val3: "1元/点",
        id: "369",
      },
      {
        video: "https://www.youtube.com/embed/Vnf8jDMpXuc?si=ctsVCF1Lzkx331Qg",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202309/6502c2b1afdff.jpg",
        // label: `直接发日本仓贴标`,
        label: `商品ラベル貼り付け-STOCKCREW<br> <span style="" class="colorB4272B">商品ラベル貼付不要の場合手数料は商品セット化作業オプションと一致</span>`,
        val1: "1.2元/点",
        val2: "1.2元/点",
        val3: "1.5元/点",
        id: "370",
      },
      {
        video: "https://www.youtube.com/embed/OS8a5KUcW9g?si=ikeYZ7YTwIKTcgDf",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/dest/202409/723806419/image(1).jpg",
        // label: `换客户定做opp带费用`,
        label: `商品セット化作業<br>（数量と種類）<br><span style="display: inline-block;" class="colorB4272B">※定額会員、SVIPの場合、</span><br><span class="colorB4272B" style="display: inline-block;">商品内容により個別相談可能</span>`,
        val1: "3点以内:1元 <br/> 4~5点:1.5元 <br/>6~7点:2.5元 <br/>8~9点:3.5元<br/> 10点以上:5元",
        val2: "3点以内:1元 <br/> 4~5点:2元 <br/>6~7点:3元 <br/>8~9点:4元<br/> 10点以上:6元",
        val3: "3点以内:1元 <br/> 4~5点:2元 <br/>6~7点:3元 <br/>8~9点:4元<br/> 10点以上:7元",
        id: "1",
      },
      {
        video: "https://www.youtube.com/embed/7SMUs4-UtIw?si=XvoL-5NwmWuoEcY8",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/64979a5d62af3.jpg",
        // label: `放感谢信宣传册等 <br> （限1个种类，2个以上按组套算）`,
        label: `チラシ、感謝手紙など印刷物入れ <br> <span style="">（1種類のみ、2種類以上はセット作業代請求）</span>`,
        val1: "無料",
        val2: "0.3元/点",
        val3: "0.5元/点",
        id: "23",
      },
      {
        video: "https://www.youtube.com/embed/B02mP2edue4?si=44tVi-TUCJOQxk1j",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202310/652c88ef87932.jpg",
        // label: `换客户定做opp带费用`,
        label: `通常OPP袋入替<span class="colorB4272B">（opp袋＋人件費）</span><br><span  class="colorB4272B">（商品毎に数量が多い場合や、商品の大きさによっては、別途追加料金をご請求させて頂く場合がございます。）</span>`,
        val1: '基本無料<span class="colorB4272B">※</span>',
        val2: '基本無料<span class="colorB4272B">※</span>',
        val3: "0.5元/点",
        id: "16",
      },
      {
        video: "https://www.youtube.com/embed/BdGYQcmqSiA?si=q2FzHpU11o3F5Y7W",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202307/64ad0665e54ec.jpg",
        // label: `换客户定做opp带费用`,
        label: `別注OPP袋入替<span class="colorB4272B">（opp袋＋人件費）</span><br><span  class="colorB4272B">（商品毎に数量が多い場合や、商品の大きさによっては、別途追加料金をご請求させて頂く場合がございます。）</span>`,
        val1: '基本無料<span class="colorB4272B">※</span>',
        val2: '基本無料<span class="colorB4272B">※</span>',
        val3: "0.3元/点",
        id: "2",
      },
      {
        video: "https://www.youtube.com/embed/Q_z0UP12eXw?si=92zTOEAqGifDty3M",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497b5747404d.jpg",
        label: `織りネーム縫い付け`,
        val1: "1.5元/点",
        val2: "1.5元/点",
        val3: "1.5元/点",
        id: "17",
      },
      {
        video: "https://www.youtube.com/embed/KBiKYAjWN-U?si=ZylY3SEDREhz-FU8",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a2a87a4e6.jpg",
        label: `織りネーム取り外し`,
        val1: "1元/点",
        val2: "1元/点",
        val3: "1元/点",
        id: "18",
      },
      {
        video: "https://www.youtube.com/embed/HwuRSqPQSps?si=d1H4-jqP5kc3QNl5",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a2c394141.jpg",
        // label: `去纸标`,
        label: `紙タグ・下げ札取り外し`,
        val1: "無料",
        val2: "無料",
        val3: "無料",
        id: "19",
      },
      {
        video: "https://www.youtube.com/embed/6yFxcN97wls?si=s2c-fP_ykdS1AnkD",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202307/64ad06b51df0f.jpg",
        // label: `挂吊牌`,
        label: `紙タグ・下げ札取り付け`,
        val1: "0.3元/点",
        val2: "0.5元/点",
        val3: "0.5元/点",
        id: "20",
      },
      {
        video: "https://www.youtube.com/embed/GZ1wmR4Az3I?si=qNBt4kVh4ZbugmsR",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a12d49f99.jpg",
        // label: `缝布标`,
        label: `洗濯（品質）タグ取り外し`,
        val1: "1元/点",
        val2: "1元/点",
        val3: "1元/点",
        id: "3",
      },
      {
        video: "https://www.youtube.com/embed/n44Qz1UmGmw?si=6FNj9dTQUvHOayW6",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/64979b27e0050.jpg",
        // label: `剪布标`,
        label: `洗濯（品質）タグ縫い付け`,
        val1: "1.5元/点",
        val2: "1.5元/点",
        val3: "1.5元/点",
        id: "4",
      },
      {
        video: "https://www.youtube.com/embed/w-59Is3ibRc?si=8_0x0NtI-DP6Fd4s",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a1570fcbb.jpg",
        // label: `纽扣开孔`,
        label: `ボタンホール開け`,
        val1: "無料",
        val2: "無料",
        val3: "無料",
        id: "5",
      },
      {
        video: "https://www.youtube.com/embed/UGxzz3AVX2A?si=xQab2w7glhXQ-o8A",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a37c28a5f.jpg",
        // label: `商品尺寸测量`,
        label: `商品採寸`,
        val1: "無料<br/> 抜き取り検査",
        val2: "無料<br/> 抜き取り検査",
        val3: "X",
        id: "6",
      },
      {
        video: "https://www.youtube.com/embed/Z6Vs4ojugSA?si=LiC3QLmgcxohuDkp",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/64979ccfb08ed.jpg",
        // label: `换尺寸 <br> （尺寸贴）`,
        label: `サイズシールの貼り付け<br> <span style="">（サイズシール表記）</span>`,
        val1: "無料",
        val2: "無料",
        val3: "無料",
        id: "7",
      },
      {
        video: "https://www.youtube.com/embed/xRyqbH5GM2A?si=SZmGCoSgxaJwxUIl",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/64979b036be3a.jpg",
        // label: `废弃盒子opp袋包装 <br> （鞋子等）`,
        label: `外箱廃棄opp袋梱包へ切替（靴など）<br><span>OPP袋代金を含む</span>`,
        val1: "無料",
        val2: "無料",
        val3: "0.5元/点",
        id: "8",
      },
      {
        video: "https://www.youtube.com/embed/JjVFvNnH0KU?si=S82O4agAc2cyu8xp",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202309/6502c28fd930b.jpg",
        // label: `贴年龄贴标`,
        label: `おもちゃ年齢表示`,
        val1: "無料",
        val2: "無料",
        val3: "0.3元/点",
        id: "9",
      },
      {
        video: "https://www.youtube.com/embed/OFqGpzIfMjs?si=SSuum7vUR8YATUOk",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202309/6502c1f46222b.jpg",
        // label: `made in China标`,
        label: `made in China`,
        val1: "無料",
        val2: "無料",
        val3: "0.3元/点",
        id: "10",
      },
      {
        video: "https://www.youtube.com/embed/LPbLAxpr8p8?si=KO4zuSK_kkpgwSOA",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202405/66444e9195b5e.jpg",
        // label: `贴OEM贴标`,
        label: `LOGOシール貼り付け<br> <span style="" class="colorB4272B">シールは別途作成必要があり、作成最低枚数はショップによって異なります。</span>`,
        val1: "無料",
        val2: "無料",
        val3: "0.5元/点",
        id: "11",
      },
      {
        video: "https://www.youtube.com/embed/b1aGGcNBg6k?si=tAmp5pzUejI4Ffb9",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a54716c02.jpg",
        // label: `压缩机器`,
        label: `圧縮包装（袋別途費用）`,
        val1: "1元/点",
        val2: "1.5元/点",
        val3: "2元/点",
        id: "12",
      },
      {
        video: "https://www.youtube.com/embed/HtcAuWi-scI?si=ZEK5qu7DCBIzJ1zK",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202309/65040a456656e.jpg",
        label: `シュリンク包装 <br> <span style="" class="colorB4272B">（50点以上より対応） </span>`,
        val1: "0.3元/点",
        val2: "0.4元/点",
        val3: "0.8元/点",
        id: "13",
      },
      {
        video: "https://www.youtube.com/embed/SMuQeLKYs_Q?si=1IgEOO29isk0c8Ge",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/dest/202409/206540606/image2(1).jpg",
        // label: `通电检查`,
        label: `通電検査<br> <span style="" class="colorB4272B">（商品により別途相談の場合があります） </span>`,
        val1: "0.5元/点",
        val2: "0.5元/点",
        val3: "1元/点",
        id: "14",
      },
      {
        video: "https://www.youtube.com/embed/DL6XWnT9XO8?si=enZ58eeLsR6BymPV",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202309/64f7e1c22c67e.jpg",
        // label: `割布`,
        label: `生地裁断 <br><span style="" class="colorB4272B">カットサイズにより値段相談</span>`,
        val1: "3元/点",
        val2: "3元/点",
        val3: "X",
        id: "15",
      },
      {
        video: "https://www.youtube.com/embed/9wKhtSnx8jY?si=DulpypE3-NAii5_Z",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202312/656fd37711e57.jpg",
        // label: `挂烫`,
        label: `アイロン掛け <br><span  style="" class="colorB4272B">（※テスト段階、<br>必要時担当者へ確認が必要）</span>`,
        val1: "3元/点",
        val2: "4元/点",
        val3: "5元/点",
        id: "21",
      },
      {
        video: "https://www.youtube.com/embed/99Du-z37aMA?si=dLpvoSlrEbD3Wtk2",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202310/65220c8511166.jpg",
        // label: `包气泡膜`,
        label: `プチプチ梱包 <br/> <span style="" class="colorB4272B">※大型商品の場合、別途相談</span>`,
        val1: "1元/点",
        val2: "1.5元/点",
        val3: "2元/点",
        id: "22",
      },
      // {
      //     video: '',
      //     img: '',
      //     // label: `气泡膜包装`,
      //     label: `プチプチ梱包`,
      //     val1: '2元/点',
      //     val2: '2元/点',
      //     val3: '2元/点',
      //     id: '15',
      // },
      {
        video: "https://www.youtube.com/embed/Vjygqe7zC18?si=uDfinq8cajPc0eWU",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a59e18fdf.jpg",
        // label: `珍珠棉包装`,
        label: `クッション梱包`,
        val1: "1元/点",
        val2: "1.5元/点",
        val3: "2元/点",
        id: "24",
      },
      {
        video: "https://www.youtube.com/embed/JzGfjQYj9gY?si=SDO5VFXFJ29CIRrr",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a56726379.jpg",
        label: `別注折りたたみパッケージへの入替 <br> <span style="" class="colorB4272B">（パッケージの作成依頼も対応可能<br>　※作業時間に応じ別途追加料金請求する場合もございます）</span>`,
        val1: "2元/点",
        val2: "2元/点",
        val3: "2元/点",
        id: "25",
      },
      {
        video: "https://www.youtube.com/embed/g_yP_Po-tQY?si=gxtOTWtzWL_VqCwX",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a43b0cc8e.jpg",
        // label: `四角折叠`,
        label: `4角出ないテープ貼り付け包装`,
        val1: "1元/点",
        val2: "1.5元/点",
        val3: "2元/点",
        id: "26",
      },
      {
        video: "",
        img: "",
        // label: `四角折叠`,
        label: `長尺物計測<span class="colorB4272B">（時間換算対応：15元/30分）</span><br><span  class="colorB4272B">（長尺物商品の検品をお勧めいたします。こちらを追加していない場合は、不良が発生すると自己責任になりますのでご了承ください。）</span>`,
        val1: "O",
        val2: "O",
        val3: "相談可",
        id: "28",
      },
      {
        video: "https://www.youtube.com/embed/2oulPT3Rld0?si=mcA5kuqEoKG41uKd",
        img: "",
        // label: `四角折叠`,
        label: `検針サービス<br><span class="colorB4272B">大型バッグ（三辺合計30㎝以上）：1元/点<br>例：ショルダーバッグ等<br>小型バッグ（三辺合計30㎝以下）：0.3元/点<br>例：小銭入れ等<br>アパレル類商品は相談対応可</span>`,
        val1: "O",
        val2: "O",
        val3: "O",
        id: "575757567",
      },
      {
        video: "",
        img: "",
        // label: `四角折叠`,
        label: `乾燥剤同梱<br>`,
        val1: "0.5元/点",
        val2: "0.5元/点",
        val3: "0.5元/点",
        id: "15641561",
      },
    ],
  },
  // {
  //
  //     title: '検品について',
  //     type: "検品について",
  //     textList: [],
  //     row: []
  // },
  {
    // title: '发送option费',
    id: "deliveryCost",
    title: "配送オプション料金",
    textList: [
      // {text:`发送option费是指对包裹进行特殊包装所需支付的费用。 <br>该费用通常适用于那些需要经过额外包装的包裹，例如易碎物品或贵重物品等，RAKUMART相信，消费者的满意度是其最大的关注点之一。<br> 因此，在发送重要的包裹时，RAKUMART会建议客户选择发送option。 `},
      // {text:`对于下列费用一览表中没有的操作与服务，我们也会灵活应对，请您有问题务必随时咨询。`}
      {
        text: `配送オプション料金は、荷物を特別な包装で送付するために必要な費用を指します。 <br>この料金は通常、追加の包装が必要な商品（例：壊れやすい品物や高価な品物など）に適用されます。RAKUMARTは消費者の満足度を最優先事項と考えています。<br> そのため、重要な荷物を送る際には、お客様に配送オプションを選択することをおすすめします。 `,
      },
      {
        text: `料金表に掲載されていない操作やサービスについても柔軟に対応いたしますので、ご質問がある場合はお気軽にお問い合わせください。`,
      },
    ],
    row: [
      {
        video: "https://www.youtube.com/embed/0c4SF5iNBtw?si=A9KW3TCZOryywdLL",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/dest/202409/140287553/image1(1).jpg",
        // label: `出货明细贴标`,
        label: `出荷明細貼り付け`,
        val1: "無料",
        val2: "無料",
        val3: "1元/点",
        id: "27",
      },
      // {
      //     video: '',
      //     img: '',
      //     // label: `箱子护角`,
      //     label: `ダンボール箱の角補強`,
      //     val1: '2元/箱',
      //     val2: '3元/箱',
      //     val3: '4元/箱',
      //     id: '28',
      // },
      {
        video: "https://www.youtube.com/embed/r95dZXdKyb0?si=_d1BLHE8Oijp5Noh",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202311/6554901c4b599.jpg",
        // label: `贴重量超过、禁止倒放、<br/> 易碎品标等`,
        label: `重量超過、割れやすい、<br/> 天地無用`,
        val1: "無料",
        val2: "無料",
        val3: "無料",
        id: "29",
      },
      {
        video: "https://www.youtube.com/embed/zOGbuqayUSU?si=192sXvO5C9ldAXk2",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202311/6554907ea07a1.jpg",
        // label: `外箱贴标`,
        label: `箱ラベル貼り付け（配送ラベル）※FBA、RSL等`,
        val1: "無料",
        val2: "無料",
        val3: "無料",
        id: "30",
      },
      {
        video: "https://www.youtube.com/embed/0UdYAQ9r3HQ?si=VhP42UIK9qNiaN0J",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202312/657917a5e8d07.jpg",
        // label: `发货清单`,
        label: `箱当たり明細リスト`,
        val1: "無料",
        val2: "無料",
        val3: "無料",
        id: "31",
      },
      {
        video: "https://www.youtube.com/embed/sgdViNUI03g?si=8TUbtAek2eBF9Vv1",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202401/65978e011fe9e.jpg",
        // label: `换新箱子发货`,
        label: `国際発送用２重新品ダンボール`,
        val1: "無料",
        val2: "無料",
        val3: "無料",
        id: "32",
      },
      {
        video: "https://www.youtube.com/embed/tMoGVJ6BZRs?si=gSBLcfIy-KB1KNmM",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202401/65978e7265a8f.jpg",
        // label: `箱子垫气泡膜`,
        label: `ダンボール箱プチプチ梱包`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "33",
      },
      {
        video: "",
        img: "",
        // label: `托盘发货`,
        label: `パレット梱包`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "34",
      },
      {
        video: "https://www.youtube.com/embed/yibk6HndiF4?si=eZSt4cl1gYVaPgNE",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202310/652c885e695c2.jpg",
        // label: `打木架`,
        label: `木製パレット梱包`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "35",
      },
      {
        video: "",
        img: "",
        // label: `装柜发货`,
        label: `コンテナ発送`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "36",
      },
      // {
      //   video: '',
      //   img: 'https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/dest/202411/745052269/%E8%BD%AC%E5%AF%84%E8%AE%A2%E5%8D%95%E7%AE%B1%E5%AD%90%E8%B4%B9%E7%94%A8.jpg',
      //   // label: `装柜发货`,
      //   label: `転送<br><span class="colorB4272B">転送の際の箱梱包手数料はサイズにより異なります。<br>通常サイズの場合、1箱あたり10元、又は15元となります。<br>具体的な金額は右側の画像をご参照下さい。<br>特殊サイズは担当者までお問い合わせください。 </span>`,
      //   val1: '商品代金3%-5%',
      //   val2: '商品代金3%-5%',
      //   val3: '商品代金3%-5%',
      //   id: '1000',
      // }
    ],
  },
  {
    // title: '其他费用',
    title: "その他",
    id: "other",
    textList: [
      // {text:`RAKUMART 公司提供了详细的其他费用收费标准，以确保客户能够清楚地了解每个项目的具体费用。<br/> 例如，当客户定制产品时，我们按照多项因素计算费用，如所定制的商品数量、制造工艺、材料价格等等。<br/>  我们始终保持透明度，让客户知道所有涉及其他费用的因素，并且 能够在付款前得到详细的价格清单。  `},
      // {text:`对于下列费用一览表中没有的操作与服务，我们也会灵活应对，请您有问题务必随时咨询。`}
      {
        text: `RAKUMARTは、お客様が各項目の具体的な料金を明確に把握できるよう、詳細なその他の料金の徴収基準を提供しています。<br/> 例えば、お客様が製品を特注する場合、製品の数量、作成内容、材料価格など、複数の要素に基づいて料金を計算します。<br/>私たちは常に透明性を保ち、お客様に関連するすべての料金要素を明示し、支払い前に詳細な価格リストを提供しています。  `,
      },
      {
        text: `料金表に掲載されていない操作やサービスについても柔軟に対応いたしますので、ご質問がある場合はお気軽にお問い合わせください。`,
      },
    ],
    row: [
      {
        video: "https://www.youtube.com/embed/351wdOoAuak?si=uXeXeu3F_ZMQmlYk",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a676003ba.jpg",
        // label: `做水洗标（单色）`,
        label: `洗濯タグ作成 <br><span style="" class="colorB4272B">（10点以下対応不可300点以上別途見積り）</span><br><span style="" class="colorB4272B">【サイズ指定は担当者へ確認が必要】</span>`,
        // val1: '0-50 点          1.5 元<br>50-100 点      1 元<br>100-300 点    0.8 元<br>300-500 点    0.5  元',
        // val2: '0-50 点          1.5 元<br>50-100 点      1 元<br>100-300 点    0.8 元<br>300-500 点    0.5  元',
        // val3: '0-50 点          1.5 元<br>50-100 点      1 元<br>100-300 点    0.8 元<br>300-500 点    0.5  元',
        val1: "0.5元/点",
        val2: "0.5元/点",
        val3: "0.8元/点",
        id: "38",
      },
      {
        video: "https://www.youtube.com/embed/UGoyboR_lbw?si=mnFDd1VAqpXnstfY",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202306/6497a65187934.jpg",
        // label: `做吊卡（单色）`,
        label: `下げ札タグ作成 <br><span style="" class="colorB4272B">（10点以下対応不可300点以上別途見積り）</span><br><span style="" class="colorB4272B">【サイズ指定は担当者へ確認が必要】</span>`,
        val1: "0.5元/点",
        val2: "0.5元/点",
        val3: "0.8元/点",
        id: "39",
      },
      {
        video: "",
        img: "",
        // label: `宣传册制作`,
        label: `チラシ/感謝手紙など作成<br> <span style="">（一定量は別途見積） </span>`,
        val1: "0.5元/点",
        val2: "0.8元/点",
        val3: "1元/点",
        id: "40",
      },
      {
        video: "",
        img: "",
        // label: `PSE/PSC等的认证`,
        label: `PSE/PSC/など`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "41",
      },
      {
        video: "",
        img: "",
        // label: `箱子护角`,
        label: `RCEP原産地証明書`,
        val1: "150元/回",
        val2: "150元/回",
        val3: "150元/回",
        id: "42",
      },
      {
        video: "",
        img: "",
        // label: `鉴定书 <br/>（素材、荷物運送条件）`,
        label: `鑑定書 <br/>（素材、荷物運送条件）`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "43",
      },
      {
        video: "",
        img: "",
        label: `YKKブランドの取り扱い`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "X",
        id: "44",
      },
      {
        video: "",
        img: "",
        // label: `食品届`,
        label: `食品届け`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "45",
      },
      {
        video: "",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202405/663ed47730f62.jpg",
        // label: `移印标等的制作`,
        label: `ステッカー/織ネーム/名刺、カード作成補助材料OEM`,
        val1: "O",
        val2: "O",
        val3: "O",
        id: "46",
      },
      // {
      //     video: '',
      //     img: '',
      //     // label: `织标的制作`,
      //     label: `織りネーム作成`,
      //     val1: 'O',
      //     val2: 'O',
      //     val3: 'O',
      //     id: '45',
      // },
      // {
      //     video: '',
      //     img: '',
      //     // label: `吊卡的制作`,
      //     label: `下げ札作成`,
      //     val1: 'O',
      //     val2: 'O',
      //     val3: 'O',
      //     id: '46',
      // },
      // {
      //     video: '',
      //     img: '',
      //     // label: `宣传册、感谢信的制作`,
      //     label: `チラシ、感謝手紙作成`,
      //     val1: 'O',
      //     val2: 'O',
      //     val3: 'O',
      //     id: '47',
      // },
      // {
      //     video: '',
      //     img: '',
      //     // label: `名片、卡片的制作`,
      //     label: `名刺、カード作成`,
      //     val1: 'O',
      //     val2: 'O',
      //     val3: 'O',
      //     id: '48',
      // },
      {
        video: "",
        img: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202405/663ed4f33a4cb.jpg",
        // label: `印logo辅料的定做`,
        label: `ロゴ入れ袋`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "47",
      },
      {
        video: "",
        img: "",
        // label: `印logo、指定大小箱子的制作`,
        label: `ロゴ入れパッケージ／ダンボール`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "48",
      },
      {
        video: "",
        img: "",
        // label: `数据共享：<br/> 发票、税单、清单等`,
        label: `データー共有：<br/> インボイス作成、物流請求書`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "相談対応",
        id: "49",
      },
    ],
  },
  {
    // title: '根据会员级别不同',
    title: "お客様の販売状況に応じ多種な対応サポート",
    id: "itDependsOnTheMembershipLevel",
    textList: [
      // {text:`我们在产品定制的方面也有很大的优势，我们会根据客户需求来定制产品，可谓是周到贴心。<br/> 在RAKUMART，对客户的高品质服务体验是我们一向的追求。<br/> 我们相信，只要您体验过我们的服务，就一定会成为我们的长期合作伙伴。<br/> 如果您寻找一个值得信赖、细致入微的合作伙伴，RAKUMART将会是您的最佳选择。 `},
      // {text:`对于下列费用一览表中没有的操作与服务，我们也会灵活应对，请您有问题务必随时咨询。`}
      {
        text: `製品のOEM、ODMにおいて、私たちは大きなアドバンテージを持っており、お客様の要求に基づいて製品を特注させていただきます。<br/> RAKUMARTでは、お客様の高品質なサービス体験を追求しており<br/> 一度弊社のサービスを体験していただければ、必ず長期的なパートナーとなっていただけると信じています<br/> 信頼できる、細心の注意を払ったパートナーをお探しの場合は、RAKUMARTが最適な選択肢です。 `,
      },
      {
        text: `料金表に掲載されていない操作やサービスについても柔軟に対応いたしますので、ご質問がある場合はいつでもお気軽にお問い合わせください。`,
      },
    ],
    row: [
      {
        video: "",
        img: "",
        // label: `仓库保管`,
        label: `倉庫保管`,
        val1: "180日",
        val2: "90日",
        val3: "60日",
        id: "50",
      },
      {
        video: "",
        img: "",
        // label: `商品信息反映`,
        label: `商品情報翻訳`,
        val1: "O",
        val2: "O",
        val3: "X",
        id: "51",
      },
      {
        video: "",
        img: "",
        // label: `找工厂）`,
        label: `工場リサーチ`,
        val1: "O<br/> （制限なし）",
        val2: "O <br/> （3回制限あり）",
        val3: "X",
        id: "52",
      },
      {
        video: "",
        img: "",
        // label: `找商品`,
        label: `商品リサーチ`,
        val1: "2元/URL",
        val2: "3元/URL",
        val3: "X",
        id: "53",
      },
      {
        video: "",
        img: "",
        // label: `API`,
        label: `API（システムカスタマサービス）`,
        val1: "相談対応",
        val2: "相談対応",
        val3: "X",
        id: "54",
      },
      // {
      //     video: '',
      //     img: '',
      //     // label: `解冻1688账号`,
      //     // label: `アリババアカウント凍結の解除 <br><span style="" class="colorB4272B">（必要書類：</span><br><span style="" class="colorB4272B">１、ユーザー名</span><br><span style="" class="colorB4272B">２、パスワード</span><br><span style="" class="colorB4272B">３、パスポートまたは身分証明できる写真</span><br><span style="" class="colorB4272B">４、手に持った様子の写真）</span>`,
      //     label: `アリババアカウント凍結の解除`,
      //     val1: 'O<br/> 無料',
      //     val2: 'O<br/> 無料',
      //     val3: 'X',
      //     id: '55',
      // },
      {
        video: "",
        img: "",
        // label: `市场采购`,
        label: `市場仕入れ`,
        val1: "O",
        val2: "O",
        val3: "X",
        id: "56",
      },
      {
        video: "",
        img: "",
        // label: `周末对应`,
        label: `週末カスタマー対応`,
        val1: "<span>土日</span>O<br/><br/> 可能",
        val2: "<span>土曜日</span>O<br/><span>日曜日</span>X<br/> ※事前予約対応",
        val3: "X",
        id: "57",
      },
      {
        video: "",
        img: "",
        label: `画像検索`,
        val1: "O<br/>無制限",
        val2: "O<br/>無制限",
        val3: "O<br/>制限あり日/５０回",
        id: "58",
      },
      {
        video: "",
        img: "",
        // label: `事前调查`,
        label: `事前調査`,
        val1: "O<br/> （制限なし）",
        val2: "O<br/> （１商品５店舗、５回）",
        val3: "X",
        id: "59",
      },
      {
        video: "",
        img: "",
        label: `チャットツール指定<br/>  <span style="">（rakuchat、chatwork、wechat）</span>`,
        val1: "複数選択可能",
        val2: "複数選択可能",
        val3: "単一選択のみ",
        id: "60",
      },
      {
        video: "",
        img: "",
        // label: `手机拍照`,
        label: `写メ確認`,
        val1: "O<br/> 無料",
        val2: "O<br/> 無料",
        val3: "X",
        id: "61",
      },
      {
        video: "",
        img: "",
        // label: `来中拜访对应`,
        label: `アテンド`,
        val1: "O",
        val2: "O",
        val3: "O",
        id: "62",
      },
      {
        video: "",
        img: "",
        // label: `在线会议`,
        label: `オンライン会議`,
        val1: "O<br/> 無料",
        val2: "X",
        val3: "X",
        id: "63",
      },
      {
        video: "",
        img: "",
        // label: `学习会、说明会`,
        label: `勉強会、説明会`,
        val1: "会議",
        val2: "募集",
        val3: "募集",
        id: "64",
      },
      {
        video: "",
        img: "",
        label: `日本以外へ発送`,
        val1: "O",
        val2: "O",
        val3: "O",
        id: "65",
      },
    ],
  },
];
